<template>
<div @click="bodyClick()">
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Ready for Scan" style="min-height: 500px">
      <b-form>
        
        <b-row>
          
          <b-col md="12">

            
            <b-row class="mb-2">
              <b-col md="12">
                
                  <b-form-input
                    placeholder=""
                    v-model="employee_id"
                    @change="scannedCard()"
                    autofocus
                    ref="searchbar"
                    autocomplete="off"
                  />
                
              </b-col>

              
            </b-row>

          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  <b-modal
    id="sites-show"
    ref="sites-show"
    cancel-variant="outline-secondary"
    ok-variant="outline-warning"
    ok-title="Submit"
    cancel-title="Close"
    centered
    title="Select Site"
    @hidden="resetSite"
    @ok="markAttendance"
  >

    
    <b-form>
      <b-form-group>
        <label for="site">Project Site:</label>
        <b-form-select v-model="site" >
            <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import moment from "moment-timezone";

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      error_message:null,
      showDismissibleAlert:false,

      employee_id:'',
      site:'',
      mode:'nfc',
      sites:[],
      
    }
  },
  methods : {
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Reports',
      },{
        to:null,
        text:'NFC Attendance',
        active:true
      }];
      return item;
    
    },
    scannedCard(){

      if (this.employee_id != '') {

        this.getDetail().then(()=>{

        });

      }
      
    },
    getDetail(){
      return this.$store.dispatch(POST_API, {
         data:{
            employee_id:this.employee_id,
            
         },
         api: '/api/client-user-by-ic'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.errorAlertCF();
          } else {
              var data = this.$store.getters.getResults.data;

              if(data == null){
                this.error_message = 'Employee not found !';
                this.errorPopup();

              }else{

                if (data.site != null) {
                  this.site = data.site._id

                  this.markAttendance();
                }else{

                  this.site = data.om_sites[0]._id;
                  this.sites = data.om_sites;
                  //console.log(this.sites);
                  if (data.om_sites.length > 1) {
                    
                    this.$refs['sites-show'].show();

                  }else{
                    this.markAttendance();
                  }
                  
                }
              }
          }
      });
    },
    markAttendance(){

      return this.$store.dispatch(POST_API, {
         data:{
            employee_id:this.employee_id,
            site:this.site,
            mode:this.mode,
         },
         api: '/api/mark-attendance'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.errorPopup();
          } else {
              var data = this.$store.getters.getResults.data;

              if (data == null) {

                this.error_message = 'Please try again later !';
                this.errorPopup();

              }else{

                Swal.fire({
                  imageUrl: this.$store.getters.getResults.user.profile_thumbnail,
                  imageAlt: 'User Image',
                  title: this.$store.getters.getResults.user.full_name,
                  text: data.type == 'punch_in' ? 'Clock In Successfully At - ' + moment(data.time).format('DD-MM-YYYY HH:mm') : 'Clock Out Successfully At - '+ moment(data.time).format('DD-MM-YYYY HH:mm'),
                  timer    : 3000,
                  showConfirmButton : false

                })

                this.employee_id = '';
                this.site = '';

                if (data.type == 'punch_in') {
                  var audio = new Audio(require('@/assets/nfc_tap_in.mp3'));
                  audio.play();  
                }else{
                  var audio = new Audio(require('@/assets/nfc_tap_out.mp3'));
                  audio.play();  

                }
                
                this.$refs.searchbar.$el.focus();

                //console.log(data);

              }
              
          }
      }); 
    },
    errorPopup(){
      
      this.employee_id = '';
      this.site = '';
      
      this.$refs.searchbar.$el.focus();

      Swal.fire({
        position : 'center',
        icon     : 'warning',
        title    : this.error_message,
        timer    : 3000,
        showConfirmButton : false
      });

      var audio = new Audio(require('@/assets/beep.mp3'));
      audio.play();  
    },
    resetSite(){
      this.employee_id = '';
      this.site = '';
      this.sites = [];
      this.$refs.searchbar.$el.focus();
    },
    bodyClick(){
      console.log('body');
      this.$refs.searchbar.$el.focus();
    }
    

  },
  mounted(){
    
  }
}
</script>
